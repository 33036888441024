import React from "react"
import { Container, SectionHeading } from "./Sections"
import useMatches from "../hooks/useMatches"
import { useVNo } from "../hooks/useVerein"

function DayMatch({time, group, mno, guest}, idx) {
  const { vno } = useVNo()
  return (
    <div className="flex flex-row sm:gap-4 w-full" key={idx}>
      <div className="md:text-lg pr-4 sm:px-8">{time}</div>
      <div className="flex flex-col lg:flex-row truncate">
        <div className="md:text-lg md:w-72">
          <a className="no-underline font-normal"
            href={`https://www.rlp-tennis.de/liga/vereine/verein/mannschaften/mannschaft/v/${vno}/m/${mno}.html`}
            title={`Spielplan ${group}`}
          >{ group.slice(0, -7) }</a>
        </div>
        <div className="">{guest}</div>
      </div>
    </div>
  )
}

function DayCard({date, wday, displayDate, matches}, idx) {
  return (
    <div className="flex flex-col sm:flex-row my-8 sm:pr-8 w-full items-start sm:items-stretch bg-gray-100 dark:bg-gray-600 rounded" key={date}>
      <div className="flex flex-row sm:gap-1 sm:flex-col sm:justify-center lg:flex-row px-4 sm:px-8 py-2 sm:py-4 text-xl items-baseline sm:items-center max-sm:w-full bg-primary-700 max-sm:rounded-t sm:rounded-l">
        <div className="font-bold sm:text-center max-sm:whitespace-nowrap text-gray-100 dark:text-gray-300 lg:order-2 lg:pl-4">{displayDate}</div>
        <div className="text-lg max-sm:w-full text-white dark:text-gray-100 max-sm:text-right">{wday}</div>
      </div>
      <div className="flex flex-col justify-center max-lg:gap-4 w-full p-4 sm:border-l border-gray-300 dark:border-gray-500">
        { matches && matches.map(DayMatch) }
      </div>
    </div>
  )
}

export function UpcomingMatches() {
  const data = useMatches()

  const now = new Date(Date.now() - 5*36e5).toISOString();
  const inTwoWeeks = new Date(Date.now() + 14688e5).toISOString();
  const homeMatches = data.matches.filter(function (m) { return m.isHome && m.datetime > now })
  const upcoming = homeMatches.filter(function (m) { return m.datetime < inTwoWeeks })

  let day = {}
  let dayMatches = []
  const calendar = []

  upcoming
    .forEach(function (match, idx) {
      if (day.date !== match.date) {
        dayMatches = []
        day = {date: match.date, wday: match.wday, displayDate: match.displayDate, matches: dayMatches}
        calendar.push(day)
      }
      dayMatches.push(match)
    })

  return upcoming.length ? (
      <Container className="max-w-7xl">
        <SectionHeading>{data.intro.heading}</SectionHeading>
        <div
          className="max-w-none prose dark:prose-invert mt-8 md:text-lg"
          dangerouslySetInnerHTML={{ __html: data.intro.text1?.html }}
        />
        <div className="max-w-none prose dark:prose-invert">
          {calendar.map(DayCard)}
        </div>
      </Container>
  ):(
      <Container className="max-w-7xl">
        <SectionHeading>{data.intro.heading}</SectionHeading>
        <div className="max-w-none prose dark:prose-invert mt-8 md:text-lg">
          { homeMatches.length
              ? `Bis zum ${homeMatches[0].displayDate} sind derzeit keine Heimpartien angesetzt.`
              : 'Es sind keine Heimspiele in den nächsten zwei Wochen angesetzt.'
          }
        </div>
      </Container>
  )
}

function MatchCard({date, time, isHome, home, guest}, idx) {
  return (
    <div className="flex flex-row sm:gap-4 w-full" key={idx}>
      <div className="md:text-lg max-sm:pr-6 sm:w-20">{date}</div>
      <div className="md:text-lg max-sm:pr-6 sm:w-20">{time}</div>
      <div className="flex flex-row truncate">
        <div className="pr-4" title={isHome ? 'Heim' : 'Auswärts'}>{isHome ? 'H' : 'A'}</div>
        <div className="">{isHome ? guest : home}</div>
      </div>
    </div>
  )
}

export function TeamMatches({mno, className}) {
  const allMatches = useMatches()

  const matches = allMatches.filter(function (m) { return m.mno === mno })

  return (
    <div className="">
      <h4>Spielplan</h4>
      {matches.map(MatchCard)}
    </div>
  )
}


